import React, { Component } from 'react';
import poems from './BlogData'; // Your poem data
import '../styles/blogMenu.css';

import prev from "../assets/prev.png"
import next from "../assets/next.png"

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 'content', // 'content' shows the table of contents
      selectedPoemId: null, // Track the selected poem by its ID
    };
    this.handlePostClick = this.handlePostClick.bind(this);
    this.handleReturnToContent = this.handleReturnToContent.bind(this);
    this.handleNextPost = this.handleNextPost.bind(this);
    this.handlePreviousPost = this.handlePreviousPost.bind(this);
  }

  // Handle clicking a title from the content page
  handlePostClick(poemId) {
    this.setState({ currentPage: 'post', selectedPoemId: poemId });
  }

  // Handle returning to the content page
  handleReturnToContent() {
    this.setState({ currentPage: 'content', selectedPoemId: null });
  }

  // Handle clicking "Next" button to show the next poem/prose
  handleNextPost() {
    const { selectedPoemId } = this.state;
    const allPoemsProses = [...poems].sort((a, b) => a.title?.localeCompare(b.title)); // Sorted list
    const currentIndex = allPoemsProses.findIndex(poem => poem.id === selectedPoemId);

    if (currentIndex < allPoemsProses.length - 1) {
      const nextPoemId = allPoemsProses[currentIndex + 1].id;
      this.setState({ selectedPoemId: nextPoemId });
    }
  }

  // Handle clicking "Previous" button to show the previous poem/prose
  handlePreviousPost() {
    const { selectedPoemId } = this.state;
    const allPoemsProses = [...poems].sort((a, b) => a.title?.localeCompare(b.title)); // Sorted list
    const currentIndex = allPoemsProses.findIndex(poem => poem.id === selectedPoemId);

    if (currentIndex > 0) {
      const prevPoemId = allPoemsProses[currentIndex - 1].id;
      this.setState({ selectedPoemId: prevPoemId });
    }
  }

  // Render the content page (table of contents)
  renderContentPage() {
    const poemsList = poems.filter(poem => poem.type === 'poem').sort((a, b) => a.title?.localeCompare(b.title));
    const proseList = poems.filter(poem => poem.type === 'prose').sort((a, b) => a.title?.localeCompare(b.title));

    return (
      <div className="page-container">
        <h1 className="content-header">Poems and Proses</h1>
        <div className="content-section">
          <h2>Poems</h2>
          {poemsList.map(poem => (
            <div key={poem.id} className="content-item" onClick={() => this.handlePostClick(poem.id)}>
              <span className="content-title">{poem?.title || "Untitled"}</span>
              <span className="content-date">{poem?.date || "Unknown Date"}</span>
            </div>
          ))}
        </div>
        <div className="content-section">
          <h2>Proses</h2>
          {proseList.map(prose => (
            <div key={prose.id} className="content-item" onClick={() => this.handlePostClick(prose.id)}>
              <span className="content-title">{prose?.title || "Untitled"}</span>
              <span className="content-date">{prose?.date || "Unknown Date"}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // Render a specific poem/prose page
  renderPostPage() {
    const { selectedPoemId } = this.state;
    const allPoemsProses = [...poems].sort((a, b) => a.title?.localeCompare(b.title)); // Sorted list
    const currentPost = allPoemsProses.find(poem => poem.id === selectedPoemId);
    const currentIndex = allPoemsProses.findIndex(poem => poem.id === selectedPoemId);

    // Ensure that currentPost exists
    if (!currentPost) {
      return <div className="error-page">Post not found.</div>;
    }

    return (
      <div className="page-container">
        <h2>{currentPost.title}</h2>
        <p className="post-date">{currentPost.date}</p>
        <div className="post-content">
          {currentPost.content}
        </div>
        <div className="navigation-buttons">
          <button
            className="prev-next-button"
            onClick={this.handlePreviousPost}
            disabled={currentIndex === 0} // Disable if it's the first post
          >
            <img src={prev} alt="prev" className="prev-icon" />
            Previous
          </button>
          <button className="return-button" onClick={this.handleReturnToContent}>
            Return to Content
          </button>
          <button
            className="prev-next-button"
            onClick={this.handleNextPost}
            disabled={currentIndex === allPoemsProses.length - 1} // Disable if it's the last post
          >
            Next
            <img src={next} alt="next" className="next-icon" />
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { currentPage } = this.state;

    return (
      <div className="blog-page">
        {currentPage === 'content' ? this.renderContentPage() : this.renderPostPage()}
      </div>
    );
  }
}