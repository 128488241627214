const subheadingsData = {
    1: [
        {
            title: "About me",
            content: (<>
                <p>
                Hi! I'm Summer Guo, an aspiring Computer Science major with a passion for using technology as a tool to solve 
                real-world problems and meet human needs. 
                               
                </p>
            </>)
        },
    ],
    2: [
        {
            title: "My CS Interests",
            content: (<>
                <p>
                My main interests include machine learning, data science, and Human-Computer Interaction 
                (HCI), where I explore how technology can empower people and create meaningful change.
                <br />
                <br />
                I’m deeply curious about emerging technologies and constantly looking 
                for ways to make tech more accessible, impactful, and relevant. 
                Research fuels my creativity, and I’m always on the hunt for the 
                next opportunity to learn, iterate, and innovate.
                </p>
            </>)
        },
    ],
    3: [
        {
            title: "Website Details",
            content: (<>
                <p>
                I decided to build this website to create a space where I can share my past artworks, projects, poems, prose, and photos I’ve taken. The site is programmed primarily using React.js.
                </p>
            </>)
        },
    ],
};

export default subheadingsData;