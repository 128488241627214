import React from 'react';
import '../styles/Modal.css';
import prev from "../assets/prev.png"
import next from "../assets/next.png"


const Modal = ({ show, image, caption, onClose, onNext, onPrev }) => {
  if (!show) {
    return null; // Don't render the modal if show is false
  }

  // Function to handle clicking outside modal content
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose(); // Close the modal if clicked on overlay
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <div className="modal-image-container">
          <button className="modal-control left" onClick={onPrev}>
          <img src={prev} alt="prev" className="icon" />
          </button>
          <img src={image} alt={caption} className="modal-image" />
          <button className="modal-control right" onClick={onNext}>
          <img src={next} alt="prev" className="icon" />
          </button>
        </div>
        <div className="modal-caption">
          <p>{caption}</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;