import React, { Component } from 'react';
import classNames from 'classnames';
import ArtCarousel from './ArtCarousel';
import "../styles/artMenu.css";

// Digital art images
import listen from "../assets/Digital/Listen.PNG";
import attack from "../assets/Digital/Attacked.PNG";
import monster from "../assets/Digital/Monster.JPG"
import pink from "../assets/Digital/Cone.PNG";
import drown from "../assets/Digital/Peace.png";
import lost from "../assets/Digital/LostFear.JPG";
import eattime from "../assets/Digital/Machine of Time.gif";
import blackbox from "../assets/Digital/Caged Illusions.gif"
import cyborg from "../assets/Digital/Cyborg.JPG";
import deadRingers from "../assets/Digital/deadRingers.JPG";
import displacement from "../assets/Digital/Displacement.JPG";
import Hugh from "../assets/Digital/Hugh.JPG";
import Inspiration from "../assets/Digital/InspirationalWestern.JPG";
import red from "../assets/Digital/Red.JPG";
import Soulphoto from "../assets/Digital/Soulphoto.JPG";
import war from "../assets/Digital/War.JPG";
import warning from "../assets/Digital/Warning.JPG";
import history from "../assets/Digital/Cycle of History.gif"
import falling from "../assets/Digital/Falling.gif"
import cheesy from "../assets/Digital/Cheesy_Mystery.png"
import azira from "../assets/Digital/Azira.png"



//trad
import Tiger1 from "../assets/Traditional/Tiger1.jpg"
import Tiger2 from "../assets/Traditional/Tiger2.jpg"
import Tiger3 from "../assets/Traditional/Tiger3.jpg"
import Tiger4 from "../assets/Traditional/Tiger4.jpg"
import Cow1 from "../assets/Traditional/Cow_bro.jpg"
import Cow2 from "../assets/Traditional/Cow_me.jpg"
import Cow3 from "../assets/Traditional/Cow_mom.jpg"
import Dragon1 from "../assets/Traditional/Dragon_bro.JPG"
import Dragon2 from "../assets/Traditional/Dragon_me.JPG"
import Dragon3 from "../assets/Traditional/Dragon_dad.JPG"
import Dragon4 from "../assets/Traditional/Dragon_mom.JPG"
import horses from "../assets/Traditional/Horses.JPG"
import weakreabbit from "../assets/Traditional/WeakRabbit.JPG"
import Rabbit1 from "../assets/Traditional/Rabbit_bro.JPG"
import Rabbit2 from "../assets/Traditional/Rabbit_me.JPG"
import Rabbit3 from "../assets/Traditional/Rabbit_parents.JPG"
import Monkey from "../assets/Traditional/Monkey.jpg"
import Tree from "../assets/Traditional/Tree.JPG"
import win from "../assets/Traditional/Win.jpg"
import ExtraTiger from "../assets/Traditional/ExtraTiger.jpg"

import Anast from "../assets/Traditional/Anast.jpg"
import Daredevil from "../assets/Traditional/Daredevil.JPEG"

export default class ArtMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenuItem: 1,
    };
  }

  handleMenuItemClick = (menuItem) => {
    this.setState({
      activeMenuItem: menuItem,
    }, () => {
      if (this.carouselRef) {
        this.carouselRef.resetToFirstImage();
      }
    });
  }

  render() {
    const { activeMenuItem } = this.state;
    const menuItems = ["DIGITAL", "TRADITIONAL"];

    // Define images and captions for digital and traditional art
    const digitalImages = [
      { src:eattime, caption: "Procrastination: Time killer" },
      { src:blackbox, caption: "Input -> din./23-29eu -> Output" },
      {src:Hugh, caption: "Portrait study"},
      {src:history, caption: "Social cycles, history"},
      {src:red, caption: "Red."},
      { src: cheesy, caption: "" },
      {src:Inspiration, caption: "Western inspired poster"},
      {src:Soulphoto, caption: "Some say that with every photograph, a piece of your soul is stolen."},
      {src:war, caption: " 白桦林"},
      {src:warning, caption: "Heavy is the head that wears the crown"},
      {src:displacement, caption: "Disconnected"},
      {src:deadRingers, caption: "Dead Ringers"},
      {src:cyborg, caption: "Cyborg dreams"},
      { src: listen, caption: "Synesthesia" },
      { src: falling, caption: "" },
      { src: azira, caption: "" },
      { src: pink, caption: "Fragility of heat" },
      { src: drown, caption: "Peace and Tranquility" },
      { src: lost, caption: "Lost and afraid" },
  
    ];

    const traditionalImages = [
      {src:win, caption: ""},
      {src:Daredevil, caption: ""},
      { src:Tiger1, caption: "" },
      { src:Tiger2, caption: "" },
      { src:Tiger3, caption: "" },
      { src:Tiger4, caption: "" },
      {src:ExtraTiger, caption: ""},
      { src:Cow1, caption: "" },
      { src:Cow2, caption: "" },
      { src:Cow3, caption: "" },
      { src:Dragon1, caption: "" },
      { src:Dragon2, caption: "" },
      { src:Dragon3, caption: "" },
      { src:Dragon4, caption: "" },
      {src:horses, caption: ""},
      {src:Anast, caption: ""},
      {src:weakreabbit, caption: ""},
      {src:Rabbit1, caption: ""},
      {src:Rabbit2, caption: ""},
      {src:Rabbit3, caption: ""},
      {src:Monkey, caption: ""},
      {src:Tree, caption: ""},
    ];

    return (
      <div className='art-menu'>
        {/* Buttons Container */}
        <div className='art-buttons-container'>
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={classNames("art-item", {
                activeArt: activeMenuItem === index + 1,
              })}
              onClick={() => this.handleMenuItemClick(index + 1)}
            >
              <h1 className='art-title'>{item}</h1>
            </div>
          ))}
        </div>

        {/* Carousel Container */}
        <div className='art-sub-container'>
          {activeMenuItem === 1 ? (
            <ArtCarousel ref={(ref) => { this.carouselRef = ref; }} images={digitalImages} />
          ) : (
            <ArtCarousel ref={(ref) => { this.carouselRef = ref; }} images={traditionalImages} />
          )}
        </div>
      </div>
    );
  }
}