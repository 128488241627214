import React, { Component } from 'react';
import projects from './projectsData';
import '../styles/projectsMenu.css';

export default class ProjectsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProject: null, // Track which project is clicked (for flipping on mobile)
    };
  }

  handleCardClick = (projectIndex) => {
    // Toggle the active project (for flipping on mobile)
    this.setState({
      activeProject: this.state.activeProject === projectIndex ? null : projectIndex,
    });
  };

  renderProjects = () => {
    return Object.keys(projects).map((key) => {
      const project = projects[key];
      const isActive = this.state.activeProject === parseInt(key);

      return (
        <div
          key={key}
          className={`project-card ${isActive ? 'flipped' : ''}`}
          onClick={() => this.handleCardClick(parseInt(key))}
        >
          {/* Front Side of the Card */}
          <div className="project-card-front">
            <img src={project.image} alt={project.title} className="project-image" />
            <h3 className="project-title">{project.title}</h3>
            <p className="project-description">{project.description.props.children}</p>
          </div>

          {/* Back Side of the Card */}
          <div className="project-card-back">
            <h3 className="project-title">{project.title}</h3>
            <p className="desc">{project.description.props.children}</p>

            {project.award && <p className="project-award">{project.award}</p>}

            <div className="link-container">
              {/* Render Code button if a GitHub link exists */}
              {project.link && (
                <a href={project.link} target="_blank" rel="noopener noreferrer">
                  Code
                </a>
              )}
              
              {/* Render Demo button if a demo link exists */}
              {project.demo && (
                <a href={project.demo} target="_blank" rel="noopener noreferrer">
                  Demo
                </a>
              )}

              {/* Render Video button if a video link exists */}
              {project.video && (
                <a href={project.video} target="_blank" rel="noopener noreferrer">
                  Video
                </a>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    return <div className="project-card-container">{this.renderProjects()}</div>;
  }
}