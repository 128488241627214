import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/nav.css';
import about from '../assets/AboutMe.png';
import photos from '../assets/Photos.png';
import art from '../assets/Art.png';
import projects from '../assets/Projects.png';
import blog from '../assets/Blog.png';

export default function Nav() {
  const location = useLocation();

  // Determine the current page's navigation class
  const getNavPositionClass = () => {
    switch (location.pathname) {
      case '/':
        return 'nav-about';
      case '/life':
        return 'nav-life';
      case '/art':
        return 'nav-art';
      case '/projects':
        return 'nav-projects';
      case '/blog':
        return 'nav-blog';
      default:
        return '';
    }
  };

  // Determine the title of the current page
  const getPageTitle = (path) => {
    switch (path) {
      case '/':
        return 'ABOUT';
      case '/life':
        return 'PHOTOS';
      case '/art':
        return 'ART';
      case '/projects':
        return 'PROJECTS';
      case '/blog':
        return 'BLOG';
      default:
        return '';
    }
  };

  const navPositionClass = getNavPositionClass();

  const isCurrentPage = (navClass) => {
    return navClass === navPositionClass;
  };

  // Function to handle link clicks
  const handleNavClick = (to, isCurrent) => {
    if (isCurrent) {
      // If the user clicks the current page, reload the page
      window.location.reload();
    } else {
      // Otherwise, allow React Router's default behavior
      return;
    }
  };

  // Render each navigation link with both the image and the text label
  const renderNavLink = (to, imgSrc, altText, navClass) => {
    const isCurrent = isCurrentPage(navClass);
    const linkClass = isCurrent ? 'nav-link current' : 'nav-link';
    const pageTitle = getPageTitle(to);

    return (
      <Link 
        to={to} 
        className={linkClass}
        onClick={(e) => {
          if (isCurrent) {
            e.preventDefault(); // Prevent default navigation if it's the current page
            handleNavClick(to, isCurrent);
          }
        }}
      >
        <img src={imgSrc} alt={altText} />
        <h1 className="page-title">{pageTitle}</h1> {/* Always show the title */}
      </Link>
    );
  };

  return (
    <nav className={`nav ${navPositionClass}`}>
      {renderNavLink('/', about, 'About Me Icon', 'nav-about')}
      {renderNavLink('/life', photos, 'Photograph Icon', 'nav-life')}
      {renderNavLink('/art', art, 'Art Icon', 'nav-art')}
      {renderNavLink('/projects', projects, 'Projects Icon', 'nav-projects')}
      {renderNavLink('/blog', blog, 'Blog Icon', 'nav-blog')}
    </nav>
  );
}