import React, { Component } from 'react'
import Avatar from "../avatar/Avatar.js"
import ArtMenu from "./ArtMenu.js"
import "../styles/Art.css"

export default class About extends Component {
  render() {
    return<>
    <div className="art-page" />
      <Avatar page="art" />
      <ArtMenu />
      </>
  }
}
