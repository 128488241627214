import React, { useState, useEffect } from "react";
import "../styles/playerStats.css";

// var month_diff = Date.now() - new Date("06/21/2007").getTime();
// var age_diff = new Date(month_diff);
// const age = Math.abs(age_diff.getUTCFullYear() - 1970);

// Words to cycle through
const words = ["Artist", "Poet", "Musician", "Cinephile", "Foodie", "Inventor"];

const PlayerStats = () => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);  // Track the current word
    const [displayedText, setDisplayedText] = useState("");       // What’s shown on screen
    const [isDeleting, setIsDeleting] = useState(false);          // Whether we are deleting
    const [typingSpeed, setTypingSpeed] = useState(50);          // Typing speed
    const [pauseBeforeTyping, setPauseBeforeTyping] = useState(false); // Control pause between words

    useEffect(() => {
        const currentWord = words[currentWordIndex];

        // Clear the text when switching words
        if (!isDeleting && pauseBeforeTyping) {
            setDisplayedText(""); // Reset displayedText before starting the next word
            setPauseBeforeTyping(false); // Reset the pause flag
            return; // Exit early to avoid running the typing logic prematurely
        }

        const handleType = () => {
            if (!isDeleting) {
                // Typing: add one character at a time
                setDisplayedText(currentWord.slice(0, displayedText.length + 1));

                // If the full word is typed, pause before starting to delete
                if (displayedText === currentWord) {
                    setTypingSpeed(1000); // pause before deleting
                    setTimeout(() => setIsDeleting(true), 500); // Start deleting after pause
                } else {
                    setTypingSpeed(150); // Slower typing speed
                }
            } else {
                // Deleting: remove one character at a time
                setDisplayedText(currentWord.slice(0, displayedText.length - 1));

                // When the word is fully deleted
                if (displayedText === "") {
                    setIsDeleting(false);
                    setPauseBeforeTyping(true); // Trigger the pause before typing the next word
                    setCurrentWordIndex((prev) => (prev + 1) % words.length); // Move to next word
                    setTypingSpeed(300); // Pause before typing the next word
                } else {
                    setTypingSpeed(100); // Faster deleting speed
                }
            }
        };

        // Set a timeout for typing or deleting
        const typingTimeout = setTimeout(handleType, typingSpeed);

        // Cleanup the timeout on component unmount or when dependencies change
        return () => clearTimeout(typingTimeout);
    }, [displayedText, isDeleting, typingSpeed, currentWordIndex, pauseBeforeTyping]);

    return (
        <div id="playerStats">
            <h1 className="Name">SUMMER GUO <br />
            {/* Age {age} <br /> */}
            <div className="identity">
            {displayedText}
                <span className="cursor">|</span>
                </div>
            </h1>
        </div>
    );
};

export default PlayerStats;