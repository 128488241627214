import React, { Component } from 'react'
import ProjectsMenu from "./ProjectsMenu"

export default class Projects extends Component {
  render() {
    return (
      <>
      <ProjectsMenu />
      </>
    )
  }
}
