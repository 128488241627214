const poems = [
  {
    id: 1,
    title: "Downpour",
    content: `
Hope is the rain
Falling in bouts,
into the drain,
when you have no doubts.
Slithering to the city's core,
fresh and vigorous
We sing for more,
feign ignorance.

Yet when engulfed, we have,
it floods the streets-
screeching and ravaging, through
our city's grease, but
Trivial and ignored,
what good could it be?

For where is the rain,
when the earth cries?
When its skin is cracked, crusty
and dry
A downpour then, a miracle.
Do us good, but
that's atypical

Misleadingly sly, sky prowls
clouds crowd light and
thunder growls
Yet water doesn't fall-
broken promise, 'white lie'.

Some days we forget
the presence of rain when we talk, fret
when we're distracted, it trickles
back to the sea, till summoned up
and down it will drizzle.`,
    date: "2022-01-01",
    type:"poem",
  },
  {
    id: 2,
    title: "Dream",
    content: 
  `
Some days life looks like a dream,
Particular radiance lingering
on the side of a dirt brown building, festering
A cruel yearning. Rays flood from the seam
In the harsh blue sky.
Mountains clawing at the high
heavens, alight with sprightly green

The streets littered with swaying trees, 
people saunter about, gazes 
pointed downwards.
Perhaps in a few centuries time, 
Perhaps sooner than expected,
someone will look
Back and remark
Life looked like heaven
back then.
  
  `,
    date: "2021-08-15"
    ,
    type:"poem",
  },
  {
    id: 3,
    title: "What’s a life worth?",
    content: 
  `
A fleeting line of text, scrolled over briefly,  
melts into crashing currents of static. Souls linger  
in each printed line of ink,  
escaping as whispers through stiff lips on a screen,  
through recorded disasters.

One soul slithers, grasping onto stone when  
a withering hand etches the name of its past life,  
moulding the soul into the slab,  
down.

The river continues to flow, ink fades, and recordings are buried away.  
Nothing left, only echoes of existence—  
Numbers of gold flooding away,  
memory fragments, stagnant tenants  
inhabiting the few who reminisce.  
Who remembers  
what's a life worth?
  `,
    date: "2023-06-18",
    type:"poem",
  },
  {
    id: 4,
    title: "To my tired junior year university student",
    content: 
  `
Let me shrug the leech off your hunched sides,
Patch the marks they etched 
In like jagged dagger wounds.
Your gaze invites me to explore, but that firm mouth
Betrays a harsher truth. There’s no place for me there,
But to soothe your distressed heart with a weary ear
And to drag you, through the sand, into a cold plunge.

Mother has turned to the sayings of a blind old fortune-teller,
Hoping to turn your luck around.
Superstitions are an oyster, holding a pearl of truth 
But mostly the promise and hassle of pungent meat.
I wouldn’t count on her distraction, personally.

I wish for the twinkling to return in your
Limpid eyes, for your face to be fitted with
A carefree smile. Dismiss that clawing need
For speed, for racing ahead of 
friends, equally clawing for themselves in the rubble
  `,
    date: "2023-07-11",
    type:"poem",
  },
  {
    id: 5,
    title: "Lost",
    content: 
  `
There are days when the future no longer seems so clear.
When my dream seeps of haziness, lobbed into the stratosphere.
Dangling in the misty melancholy of those days, I watch,
Through the chasm in hope, that hint of you remaining.

Marker trail of your confident steps has
Steeped deep before me,
An inheritance to discover.
Was it kindness that you tossed, or pity?
Yet, you mustn't have expected that
your bread crumb of reeking pride
Has rotted, faltering under the shine of
The moist density of clustered dreams, something
I’ve learnt, finally to embrace.
Now, your dark guides are weary, no longer
Coagulated and imposing. A pity, then, 
That I’ve awoken too late from your
Potion of daze. 
No time for adjustment, 
No time for change. 
My crooked teetering must continue,
stumble blindly between the crushing walls of success.

And when I’ve reached your glowing star,
Standing tall, piled high on my own
tower of rocks,
Finally able to tug at your warm shoulder.
Will you embrace me with that tender touch, 
Or scoff, sneer at my stubborn path?
  `,
  date:"2023-09-24",
  type:"poem",
  },
  {
    id:6,
    title: "Grateful resistance",
    content: 
    `
If I had a dream
to follow,
would I then feel
any less hollow
or simply continue,
this plateau of an existence
lacking soul and 'persistence'.

And if they provided greater assistance
instead of watching me plummet,
fall further from brilliance,
should I then stop my floundering,
start pondering and wandering through this
wide world, seeking blind comfort from the kind?

But if all has been given,
dumped and piled, never hidden.
I fear the wrath that will bubble
when finally, I wake,
no longer dormant in a muddle,
and turn around to find--
  dreams still freshly buried,
  a parent's tender patience,
  the empathy of a friend,
crumpled beneath my trample
and this heart, once ample,
now just loathsome muscle
beating to some dissonant tune.

So I'll shrivel and cower,
plug my ears and shout louder
instead of clawing away,
finding any truth that may glint
in the light like fish scales,
a star in the night.
  `,
  date:"2023-10-02",
  type:"poem", 
  },
  {
    id: 7,
    title: "Jailed, my state of mind",
    content: 
`
Strangled sleep misses the blessed kiss of dreams, incomplete.  
For when I dream, all is corrupted by the festering ache of longing, 
smothered away whenever your presence draws near.  
Yet shame summons my will away when chances appear.  
Limbs a frozen current, all I can do is pray  
that my dreams are haunted with you. For

If you looked at me, I would vacuum the breath from my lungs,  
flatten their lining of translucent film, scrape it like paint against my chest,  
just so my heart could swell and at last bear the torrent of  
warmth that bubbles when you approach near.

If you looked at me, I would plunge my nails into my wrist,  
claw at the depths and pluck out a tendon to  
craft a drumstick for that fervent heart pummelling at its prison of bones,  
so maybe then you’d finally hear the roaring beat of my love,  
singing its misfortunes of you.
`,
    date:"2023-10-12",
    type:"poem", 
    },
  {
id: 8,
title: "Time",
content: 
`
Once ran approaching, bright-eyed and curious
Like a freshly born foal.
Though it grew gradually solemn, until it’s strides
surpassed mine, the amniotic sac had yet to tear,
Yet to collapse and sigh.

Then their whispers punctured through, finally,
Ripped through the membrane of safety,
Injecting the virus of doubt until
The foal, that once bounded towards the golden paddock,
had learnt the fear of hurt, of crashing through the wall.
No longer did it 

Soon, I will have to fight against
The strengthening tides, tear through the raging waters
Just to clutch at the memories resting like shells 
On the sea bed.

Until I have to race towards it, towards the fading current of my mortality,
But my footsteps remain imprinted in wet sand,
Chasing after the blurring tear
of the waves.
`,
date:"2023-10-20",
type:"poem", 
},
{
  id:9 ,
  title: "Apology for a brother",
  content: 
  `
Something, at long last, went wrong during the third year of your stay in the States. On the call we received that fateful morning, your doctor told us an artery in your stomach burst. I imagine you cramped up in that musty little room (the one I’ve never even seen through a screen), unaware of why you’d been feeling off. Strangely lost and faint recently.
“ Alone? / 你没室友，我们又不在，谁在那里照顾你？/ I can’t imagine what might have happened if you had not gone to the hospital.”
They were close to yelling at you, wrestling with squirming frustration that cocooned from an unspeakable fear. I soothed their backs until the initial shock wore off. Perhaps it was wrong for our parents to yell at you---a patient---but it’s hard to blame them. When your pale face displayed signs of lethargy, I felt the same.
Our faces taut with fear,
Your calm, an infuriating buzzing
Gnawing my mind, heart, soul.
How could you treat life as if you are a plain pebble resting on the lake floor, impassive to any jostling and resigned to the turbulent flow of ‘fate’?
There’s this extremely uncomfortable feeling, an emotion I desperately yearn to describe. Each time I try, I forget my words, lose my mind from pent up anger and worry. From my room, I can hear them whispering about you. “Mentally unstable” is father’s go to word, while I hear mother’s superstitions resurface. She told me a fortune teller prophesied that you’ll have bad luck from now until you’re 30. Apparently, only after then will your life veer back to a smooth train track.
Pace around that small cubicle with
Covers over your ears, only the beat
Of piano keys accompanying you there.
You were always the star student, my role model of a brother, often busy on the phone helping a friend. I remember the day you left, remember us waving you off. I teared up the moment you turned your back. I recall your expression seeming so determined, so strong. Knowing you now, I suppose you must’ve been suppressing your emotions as you passed the departure gate. Before, I thought you’d adjust to life in university immediately, my smart older brother. So when I heard that you’d been skimping out on lessons recently, I really couldn’t believe it. Where was my brother who valued academics so earnestly, who I’d catch watching some educational video on youtube in his room? But I heard the crack in your voice, the faint cry begging to be released. Your gaze kept shifting, a foreign sadness concealed inside. We’d just called the day before, but you’d never told me you were feeling this bad,or perhaps I’d simply never noticed your pain until then.
Why don’t you ever confide in me
My words falling flat to your stubborn soul,
Sharing never reciprocated in kind.
Blood bond but
Why, weaker than friendship in your mind?
At the end of the call, you tell me you’re going to visit a friend now, another presence I must piece together in my mind. Your friends never seem to have names, your true thoughts never seem to reach past the screen. 
Only recently have I learnt of your obsession with music. Father explains that you often enter your own world and go on a tangent for hours on end, doing nothing but pacing around your room, listening to music. 
Torn voice announcing you’re coming home.
Fresh start, more time,
“It’ll all be better after I come home”
When you arrive home next week, it’ll be my turn as your sister to help mend your mindset, awaken your interest in life. You’ve done so much for me, we’ve shared so much of our life together, it’s only fair for me to take care of you. I only hope I can help.
  `,
  date:"2023-11-26",
  type:"prose", 
  },
{
id: 10,
title: "The sunshine boy",
content: 
`
That brother of yours is accompanied
by glints of annoyance and flickering frowns,
whenever brought up.
Your wife
         whispers that he is a putrid parasite,
Your son
         sneers that he is a latent leech,
You
         tell me he is a poor man
                     seduced by his eternal carousel of debt,
                                  doomed by the vortex of failure.

Invested in ridiculous cash grabs,
     	Your wife spits.
Addicted to rigged games,
                     Your son sighs.
Another chip of his soul gambled away,
lost in unfulfilled promises,
                                  You suggest.

Still, often you dart glances at his crumpled
picture. I watch you shake your head, 
decades of disappointment and fatigue
spilling from the curves in your neck.

You remember him, always,
as that sunshine boy who eagerly rode home
on your father’s bicycle cart. Who jumped
off and tucked the first coin he earned into your young fingers.
You remember him, not as the spectral existence of a man who
thinks to leverage your sentiment, trade one fish scale for one million more and
tug his own brother towards the festering quicksand.
It’s evident, from the way your eyes betray

that glaze of a fish
                      stuck in its seven seconds of memory,
                                  hoping to invoke insight from the past.
How that light smile that bubbles
up on your face
                                               softens the wrinkling of your skin,
                                                           dismisses all memory of the present.

`,
date:"2023-10-23",
type:"poem", 
},
{
  id:11,
  title:"Want",
  content:
  `
Your starry eyes cradled
the wisps of pain away,
soothed my guilty face with
your tender kiss.

Even now
I want to pluck the twinkle hidden
under your closed eyes,
receive that tender gaze.

Their ignorant warnings cry
that you’re gone to someplace spry,
blessed with a peaceful dawn.

My selfish fingers
want to feel your warmth
palms thread mine
one last time, 
I want more.
  `,
  date:"2023-11-26",
  type:"poem"
},
{
id: 12,
title: "Thank you",
content: 
`
To the ruddy and bright eyed bus driver
who broke the rhythm of the doors upon
Glancing at my small figure racing forwards, and
with a flick,
opened the portal to my way back home.
`,
date:"2023-10-23",
type:"poem", 
},
{
id: 13,
title: "Superstition",
content: 
`
Bad things will pass,
this luck will come to an end.
The fortunes of our ancestors won’t lie,
science of those doctors, falsified
by bitter medicine that
cures the cold and raging
flow of your soul.

Look! The flowers curl, fall pitifully
from that gentle touch of your hand.
Bad omen, child—
beware now. Watch the road attentively,
take good care of your mother,
worship the buddha devoutly.
Our mystic connection, wisdom cemented
through hurt and expectations
can't betray you.

Hear that chattering outside your window?
Cover your eyes now and hope
it’s a magpie, not a crow.
`,
date:"2024-02-24",
type:"poem", 
},
{
id: 14,
title: "Live dissection",
content: 
`
When I was young, I saw a zombie on the street,
curled against the harsh asphalt
under the dreary winter rays.
I placed my half eaten sandwich by his mud stained knees,
whispered a blessing and strolled along. 
The days went by,
I continued to leave him a souvenir whenever
I glimpsed him sleeping through the corner of my eye.

A week later, I noticed
Zombie had awoken, a twinkle in his smile. 
When I asked why, he answered 
    air was sweet, sweeter than usual recently.
I knew of his half full stomach, 
and I couldn’t help but grin. In a bout of glee,
I offered a place to stay, food for companionship, 
water without gain. 
Yet, that precious twinkle retreated
as zombie muttered 
    no thanks, would your parents agree? 
slunk back to the street.

I never saw zombie again. Never met him until
this morning in December.
Zombie, why do you
crawl a tattered arm up my trousers?
Plead with cavities of murky liquid for eyes,
hollowed skin gripping off etched cheeks of ivory.
My heart has long been poached and torn,
played with by sirens and leeches, rulers and their jokers.
Your sorrowful silence has no effect on me now.

Zombie, why do you
plead, unblinkingly, 
rancid flesh stretched over bones
of spongy liquid gold.
Is it the shadow of my towering frame that
compels you to cower as I lean near,
grip tightening around your churning heart,
the only belonging you carry.

Still, you garble a request,
    Take me away now, please!
The cold has penetrated my bone and
My stomach aches with tormented sleep.
It’s too late now, my offer has long passed.
you were the one who dismissed it: too soon, too fast

Something twitches in my unwavering silence,
crawls its way through the skittish nerves in Zombie’s face
as he turns away,
slinks back to silence on an empty street
in the form of tattered coats, clinking coins,
stained newspapers on a sidewalk. I laugh, what a treat.
`,
date:"2024-05-11",
type:"poem", 
},
{
id: 15,
title: "Ready or not, here they come",
content: 
`
Hide and Seek darling, just as always.
Shush now, promise you’ll stay still, 
silent as dust. 

Don’t you cry when they flatten our door with civility
or topple my favourite vase, 
don’t gasp when you hear the urgent baying of the neighbourhood dog—
its pack has long scurried off.
Now it’ll be kindly laid to rest, on a comfortable bed of cobblestone and dirt,
uncovered for the flies to tunnel and devour…

Your older sister hides
curled against the closet door,
willing for you to sleep
oblivious to the shattering glass and crackle of gunfire.
To awake from this long night,
see her smiling mask, 
welcoming you to a home undisturbed.

If they tarnish my body, 
know my soul remains pure.
So stop shivering, my dear,
hope they’ll never uncover the blanket,
find your infant brother shivering in the basket.

Hide and seek will be over soon,
I’ll show you where I’ve crawled to,
cheek pressed to ridged concrete floor,
hiding under the bed again.

Ready or not, here they come…
stay still, darling, silent as dust.
`,
date:"2024-04-30",
type:"poem", 
},
{
id: 16,
title: "Unconvicted Murder",
content: 
`
Dead pigeon
lies spread on the street.
Feathers flattened to smudges on tarmac
ribcage splayed like unclasped fingers and heart thumping to the brush of wind
stirred by the booming of tires streaming past.

Dead pigeon
faces the cold dust of day,
watches as blue fades to darkness streaked with flashes of red, awakened by bright grey.
Until, shoved harshly by the muzzle of a broom and dug
away, to be plopped onto piles of discarded rotting things,
among which sits a television screen, still flickering briefly, 
lingering voices from the static buzzing, with unfulfilled promises.

The deserted streets remain in frightful silence, still.
Horizon, the sky crackles, bellows and flares amber
while bated breaths and clasped hands under wrecked buildings
mumble mournings for another soul
whose drying specks of blood cling onto the stained wheels
thundering past. Carnage for some,
printed art and mere numbers, for another.
`,
date:"2024-05-03",
type:"poem", 
},
{
id: 17,
title: "Love, you mysterious wicked thing",
content: 
`
I have always wanted to fall in love, but I only realised this tug 
I felt for you was ‘love’ when:
(a) it manifested through the morning light on your cheek, drew my eyes to the dark wisps crawling down your neck (not a mess of curls, but a cryptograph into your life).

They told me love would be comforting like the muffled scent of warmth, or the hum of a distant train 
rumbling by at night, mysterious and electric. They lied.
No one whispered that love is the tormenting hurt that stems from
mute spaces between our words, every unspoken possibility. 
I am haunted by tendrils of regret, like ivy creeping over 
a forgotten wall, and gurgling fear that threatens my 
barriers of stoic apathy.

(b) the mist from my shower morphed into the twinkling glimmer of your steady gaze, my heartbeat immediately skittish.
No one warned that love will ambush,
a simmering current that sparks into wildfires, steals the rhythm of my heart
and quickens it. 
Love somehow dulls the pain of the truth, distracts me 
from the inevitable migration. 
I’m leaving, soon.
We both know you will stay.

(c) My voice thickens with false confidence, borders on arrogance. In fear you will see the trembling fragility of my thumping heart, bassline filling the quiet of my chest, a plucked melody only for you.
I’ve made this a list, conditions to a statement
from one of your lengthy textbooks. I hope that at least, this way,
you might dwell and search through these phrases
as I’ve done too many times for you.
No one needs to comfort me, assure that
this love, such a silly thing, surely will pass

yet why do I still feel a pang whenever the tip of your tongue spells
the name of another instead of mine? 
I will hide my adoration,
pass off the narwhals prancing in my stomach as cold dismissal and
escape from your gaze under every opportunity, 
still a coward.
Love, what a wicked thing. Now

I wish I didn’t fall in love, with you.
`,
date:"2024-05-12",
type:"poem", 
},
{
id: 18,
title: "Moonlight Sonata",
content: 
`
Under her quiet eye, my skin prickles, clammy and cool.
Kiss, bite the complicit mouth of that stranger
whose gaze snared me, under her guilty light.
Her whispered apologies bask over me in tingling currents
as I lie caged in a savage embrace.
Half gurgling, half choking under the thickening weight of awareness
thrust through the Pandora's box of my innocence, trembling membrane.

Surface of this frosted lake cracks and groans,
I feel the shiver of water, coldness seeping in as I crash
down, her reflection fracturing above.
The stranger hasn’t left yet; I see the razored strips of his
shadow along the surface, corrupting clear stillness.
Serenade of her blinding glow rocks me as I’m pulled deeper
into the silence, where innocence drowns,
only shadows remain.

Untethered at last.
`,
date:"2024-05-10",
type:"poem", 
},
{
id: 19,
title: "Boil Me A Cup Of Water",
content: 
`
My forehead aches with a dull burn, the persistent drumming
of pain lingering faintly. The sickness has long since crawled
to my limbs, seeming to have nibbled at my tendons and rendered me
a flimsy incapacitated mess, sinking into my bed.

At times like this, I often awake to find a
cup of boiled water by my bedside.
I know you left it for me, though I’ve never
caught you setting it down, only once hearing the clink of 
ceramic against wood while dozing in the darkness.
Somehow, it is always still faintly steaming as I guzzle it down,
the warmth a comfort that briefly soothes the aches.
How could water do that?

Someday you’ll tell me a story as I fall asleep,
of the times your own mother would boil a cup of water
for you, when you fell ill. I’ll realise then,
but not now, 
that the cup of water tasted the same to you
as it now does to me,
steeping with sweetness.

I’ll await for the day when I can grow and 
boil a cup of water, for you.
`,
date:"2024-07-09",
type:"poem", 
},
{
id: 20,
title: "Grandmother",
content: 
`
Coarse skin, bronze from toiling under 
harsh bright skies and painstakingly 
dotted with white, like patches from 
the thick cotton blankets she 
sewed until the fields fell silent

she stands when I stumble, dense dirt
threading through her hands. Her back
hunched from decades spent bent over
mounds of tousled ground

she often coaxed me with her outstretched hand
always unfurling something new, precious—
a nestle of pale peanuts, still dusted with dirt
curling into each other as if wrinkling from the 
foreign light, a green pear with slight speckling
of red around the stem, a ripened tomato, 
slightly bruised from her walk back, 
sour and sweet juice dripping 
through her tree-trunk like fingers…

I remember her warm palms clasping 
mine as I cried for my mother, trying 
to pull me into an embrace. If only 
I had accepted, if only 
I had learned the 
name behind
‘Grandmother’.
`,
date:"2024-09-15",
type:"poem", 
},
];

/*
{
id: ,
title: "",
content: 
`
`,
date:"",
type:"", 
},
*/

export default poems;