import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Nav from "./nav/Nav.js"
import About from "./about/About.js"
import Art from "./art/Art.js"
import Projects from "./projects/Projects.js"
import Blog from "./blog/Blog.js"
import "./styles/app.css";
import Background from './background/background.js'
import PlayerStats from './playerStats/PlayerStats.js'
import Life from './Life/Life.js';

const App = () => {
  return (
    <Router>
    <Nav />
    <Background/>
    <Routes>
      <Route path="/" element={<About/>}/>
      <Route path="/art" element={<Art/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/blog" element={<Blog/>}/>
      <Route path="/life" element={<Life/>}/>
    </Routes>
    <PlayerStats/>
    </Router>
  );
}

export default App
