const importAllImages = (context) => {
    const images = {}; // Initialize an empty object to store images by folder
  
    context.keys().forEach((key) => {
      const trimmedKey = key.replace('./', ''); // Remove './' from the path
      const folder = trimmedKey.includes('/') ? trimmedKey.substring(0, trimmedKey.lastIndexOf('/')) : ''; // Extract subfolder if exists
  
      // If there's no subfolder, store images directly under root
      if (!folder) {
        images.root = images.root || [];
        images.root.push(context(key));
      } else {
        // Group images by subfolder
        if (!images[folder]) {
          images[folder] = [];
        }
        images[folder].push(context(key)); // Push the image into the corresponding folder group
      }
    });
  
    return images;
  };
  
  // Load all images from the Travel folder (including subfolders)
  const travelImages = importAllImages(require.context('../assets/Images/Travel', true, /\.(png|jpe?g|svg|JPE?G|PNG)$/));
  
  // Load all images from the Food folder (no subfolders)
  const foodImages = importAllImages(require.context('../assets/Images/Food', false, /\.(png|jpe?g|svg|JPE?G|PNG)$/));
  
  // Load all images from the Events folder (no subfolders)
  const eventImages = importAllImages(require.context('../assets/Images/Events', true, /\.(png|jpe?g|svg|JPE?G|PNG)$/
));  
  // Load all images from the Daily Snaps folder (no subfolders)
  const dailyImages = importAllImages(require.context('../assets/Images/Daily', false, /\.(png|jpe?g|svg|JPE?G|PNG)$/));
  
  // Export the mediaData object with dynamically loaded images
  export const mediaData = {
    travel: travelImages,
    events: eventImages, 
    food: foodImages.root,   // Use the 'root' key for sections without subfolders
    daily: dailyImages.root,  // Use the 'root' key for sections without subfolders
  };