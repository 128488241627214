import React from 'react';
import '../styles/imageGallery.css';

const ImageGallery = ({ images }) => {
  return (
    <div className="image-gallery">
      {images.map((image, index) => (
        <div key={index} className="gallery-item">
          <img src={image} alt={`Gallery Image ${index}`} />
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;