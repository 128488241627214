import GreenCycle from "../assets/Green Cycle.png";
import TideAlert from "../assets/TideAlert.png";
import HealthyReminder from "../assets/HealthyReminder.png";
import Productive from "../assets/Productive.png"
import sugar from "../assets/SugarPaint.png"

const projects ={
    1:{
        title: "TideAlert",
        image: TideAlert,
        description: (
            <>
                <p>
                Created a mobile app and IoT system to provide real 
                time information of tides and warn of incoming floods
                 that occur during typhoon seasons, assisting citizens 
                 and estates in effectively preparing against floods.
                </p>
            </>
        ),
        link: "https://github.com/CurioScrounge/Tide-Alert",
        video: "https://youtu.be/llKSfdiuz80",
        award:<p>2024 HK Science Fair Shortlist, 
            Boost Hacks II Best Social Good Award
    </p>
    },
    2:{
        title: "Green Cycle",
        image: GreenCycle,
        description: (
            <>
                <p>A mobile recycling app with features including scanners
                    to detect the recyclability of objects and material
                    type of objects, a map to locate the nearest recycling
                    centres and bins, a levelling and reward system, 
                    a gallery page to view recycling progress, and a page linking
                     to news websites and recycling guidelines.

                </p>
            </>
        ),
        link: "https://github.com/CurioScrounge/GreenCycle",
        video: "https://youtu.be/Nr-PYDRYw8c",
        award:<p>2023 HK Science Fair Shortlist, 
            Technovation Girls Seminfinalist, 
            Technovation Girls Regional Pitch Champion
    </p>
    },
    3:{
        title: "HealthyReminder",
        image: HealthyReminder,
        description: (
            <>
                <p>This website reminds users to take regular breaks from technology. 
                    Users set a timer that runs in cycles, and when each cycle ends, 
                    a notification directs them to MuscleWiki for quick exercises. 
                    After completing an exercise, the timer automatically resumes. 
                    If users are online late at night, the website also provides gentle
                     reminders to rest and maintain a healthy balance between activity and sleep.
                     </p>
            </>
        ),
        link: "https://github.com/CurioScrounge/HealthyReminder",
        demo: "https://healthy-reminder-sigma.vercel.app/",
        video: "https://youtu.be/jtPi_uNPxkg"
    },
    4:{
        title: "Productivity program",
        image: Productive,
        description: (
            <>
                <p>
                This program helps users manage their time by tracking their online activity
                 and identifying whether they are staying productive or getting distracted.
                  Users can label websites and apps as productive or unproductive and set up
                   custom rules for specific sites. If the program detects unproductive activity
                    during scheduled work periods, it sends a warning to help users refocus. 
                    The goal is to reduce distractions and improve overall productivity.
                </p>
            </>
        ),
        link: "https://github.com/CurioScrounge/Productive_App",
        award:<p></p>
    },
    5:{
        title: "SugarPaintVR",
        image: sugar,
        description: (
            <>
                <p>
                Investigating human-computer interactions of VR 
                technology, specifically the extent to which embodied 
                interaction in Virtual Reality can be used as a tool to 
                inspire creativity in Intangible Cultural 
                Heritage (ICH), in the context of designing sugar paintings.
                <br />
                Created a Virtual Reality Sugar Painting system using Unity.
                </p>
            </>
        ),
        video: "https://youtu.be/R__RKmmPr7U",
        award:<p>Submitted to 2025 CHI Human-Computer Interaction Conference</p>
    },
};

export default projects;