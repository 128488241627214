import React, { Component } from 'react';
import ImageGallery from './ImageGallery';
import '../styles/media.css';
import { mediaData } from './mediaData'; // Import the dynamically loaded media data
import back from "../assets/back.png"

class Media extends Component {
  componentDidMount() {
    // Add scroll event listener for fade effect only if viewing images
    if (this.state.currentLevel === 'images') {
      document.addEventListener('scroll', this.handleScroll);
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    // Add or remove scroll event listener when switching between sections and images
    if (prevState.currentLevel !== this.state.currentLevel) {
      if (this.state.currentLevel === 'images') {
        document.addEventListener('scroll', this.handleScroll);
      } else {
        document.removeEventListener('scroll', this.handleScroll);
      }
    }
  }
  
  componentWillUnmount() {
    // Clean up the scroll event listener when the component unmounts
    document.removeEventListener('scroll', this.handleScroll);
  }
  
  handleScroll = () => {
    const fadeOverlay = document.querySelector('.fade-overlay');
    const fadeHeight = 100; // The height of the navbar area
  
    // Find the first image in the gallery
    const firstImage = document.querySelector('.gallery-item img');
    if (firstImage) {
      const rect = firstImage.getBoundingClientRect();
      const distanceFromTop = rect.top; // Distance of the first image from the top of the viewport
  
      // Calculate opacity based on how close the image is to the nav
      let opacity = 0;
      if (distanceFromTop <= fadeHeight) {
        opacity = 1; // Full opacity when the image is behind the nav
      }
  
      // Adjust the opacity of the fade overlay
      fadeOverlay.style.opacity = opacity;
    }
  };


  constructor(props) {
    super(props);
    this.state = {
      currentLevel: 'sections', // Tracks the current navigation level ('sections', 'subfolders', 'images')
      activeSection: null, // Tracks the active section (e.g., 'travel', 'events')
      activeSubfolder: null, // Tracks the active subfolder (e.g., 'Guangzhou', 'Guilin')
      subfolders: [], // Stores the subfolders for the current section (if any)
      images: [], // Stores the images for the current subfolder
      loading: false, // Controls the loading state
    };
  }

  // Handle section click (e.g., Travel, Food, Events, Daily Snips)
  // Handle section click (e.g., Travel, Food, Events, Daily Snaps)
handleSectionClick = (section) => {
  this.setState({ loading: true, currentLevel: 'subfolders', activeSection: section, images: [], subfolders: [], hasSubfolders: false });

  setTimeout(() => {
    let subfolders = [];
    let images = [];
    let firstImage = null;
    let hasSubfolders = false;

    if (section === 'travel') {
      const travelImages = mediaData.travel; // Access the travel images object
      subfolders = Object.keys(travelImages); // Get the subfolder names (e.g., 'Guangzhou', 'Guilin')
      hasSubfolders = true; // Travel has subfolders
    } else if (section === 'events') {
      const eventImages = mediaData.events; // Access the events images object
      subfolders = Object.keys(eventImages); // Get the subfolder names for events (e.g., 'Other', 'Research')
      hasSubfolders = true; // Events has subfolders
    } else if (section === 'food') {
      images = Object.values(mediaData.food).flat(); // Load all images under "food"
    } else if (section === 'daily') {
      images = Object.values(mediaData.daily).flat(); // Load all images under "daily"
    }

    // If there are subfolders, display them; otherwise, directly display the images
    if (subfolders.length > 0) {
      this.setState({ subfolders, hasSubfolders, firstImage, loading: false });
    } else {
      this.setState({ currentLevel: 'images', images, firstImage, hasSubfolders, loading: false });
    }
  }, 500); // Simulate loading time
};

  // Handle subfolder click (for sections like "Travel")
  handleSubfolderClick = (subfolder) => {
    this.setState({ loading: true, currentLevel: 'images', activeSubfolder: subfolder, images: [] });
  
    setTimeout(() => {
      const { activeSection } = this.state; // Get the current active section (e.g., 'travel', 'events')
      
      // Dynamically access the correct section's images
      const images = mediaData[activeSection][subfolder]; 
      
      this.setState({ images, loading: false });
    }, 500); // Simulate loading time
  };

  // Handle the back button logic
  handleBackButtonClick = () => {
    const { currentLevel, hasSubfolders } = this.state;
  
    if (currentLevel === 'images') {
      // If the section has subfolders, go back to the subfolders view
      if (hasSubfolders) {
        this.setState({ currentLevel: 'subfolders', images: [] });
      } else {
        // If no subfolders, go back to the sections view directly
        this.setState({ currentLevel: 'sections', images: [], activeSection: null });
      }
    } else if (currentLevel === 'subfolders') {
      // Go back to sections if currently in subfolders view
      this.setState({ currentLevel: 'sections', activeSection: null, subfolders: [] });
    }
  };

  render() {
    const { currentLevel, subfolders, images, loading } = this.state;
  
    // Check if subfolders and images are defined
    const hasSubfolders = subfolders && subfolders.length > 0;
    const hasImages = images && images.length > 0;
  
    return (
      <div className="media-container">
        {currentLevel === 'images' && (
          <>
            <div className="fade-overlay"></div> {/* Top fade overlay */}
            <div className="fade-overlay-bottom"></div> {/* Bottom fade overlay for mobile */}
          </>
        )}
  
        {/* Back Button */}
        {currentLevel !== 'sections' && (
          <button onClick={this.handleBackButtonClick} className="back-button">
            <img src={back} alt="Back Icon" className="back-icon" />
            Back
          </button>
        )}
  
        {/* Main Sections */}
        {currentLevel === 'sections' && (
          <div className="media-sections">
            <button 
              onClick={() => this.handleSectionClick('travel')} 
              className="media-section" 
              style={{ backgroundImage: `url(${mediaData.travel?.[Object.keys(mediaData.travel)[0]]?.[0]})` }}
            >
              <h2>TRAVEL</h2>
            </button>
            <button 
              onClick={() => this.handleSectionClick('events')} 
              className="media-section" 
              style={{ backgroundImage: `url(${mediaData.events?.[Object.keys(mediaData.events)[0]]?.[0]})` }}
            >
              <h2>EVENTS</h2>
            </button>
            <button 
              onClick={() => this.handleSectionClick('food')} 
              className="media-section" 
              style={{ backgroundImage: `url(${mediaData.food?.[0]})` }}
            >
              <h2>MY COOKING</h2>
            </button>
            <button 
              onClick={() => this.handleSectionClick('daily')} 
              className="media-section" 
              style={{ backgroundImage: `url(${mediaData.daily?.[0]})` }}
            >
              <h2>LIFE SNAPSHOTS</h2>
            </button>
          </div>
        )}
  
        {/* Subfolders (if viewing a section like Travel or Events) */}
        {currentLevel === 'subfolders' && hasSubfolders && (
          <div className="subfolder-list">
            {subfolders.map((subfolder) => (
              <div 
                key={subfolder} 
                className="subfolder" 
                onClick={() => this.handleSubfolderClick(subfolder)}
                style={{ backgroundImage: `url(${mediaData[this.state.activeSection]?.[subfolder]?.[0]})` }} // First image from the subfolder
              >
                <h2>{subfolder}</h2>
              </div>
            ))}
          </div>
        )}
  
        {/* Image Gallery (if viewing a subfolder) */}
        {currentLevel === 'images' && hasImages && <ImageGallery images={images} />}
  
        {/* Loading Indicator */}
        {loading && <div>Loading...</div>}
      </div>
    );
  }
};

export default Media;